img{
  /* visibility: hidden; */
  border-radius: 10px;
}
body {
  margin: 0;
}
:root {
  --step: 1%;
  --angle: 133deg;
  --imgsize: 300px 450px; 
}
#card-container{
  box-sizing: border-box;
  position: relative;
  max-width: 80%;
  height: 70%;
}
#card-3d{
  transform: rotateY(var(--rare-r-x)) rotateX(var(--rare-r-y));
  box-shadow: 0px 10px 20px -5px black;
  border-radius: 10px;
  perspective: 600px;
  height: 100%;
}
.card-wrapper{
  perspective: 450px;
  border-radius: 10px;
  height: 100%;
  width: auto;
  display: inline-block;
}
#card-image{
  object-fit: contain;
  height: 100%;
}
#card-image img{
  height: 100%;
}

@media screen and (max-width: 450px){
  #card-container{
    margin-bottom: 45px;
  }
}
/* *************************************************************************** */

/* GLARE */

/* *************************************************************************** */

/* GLARE */
/* .rare .card__layer1 {
  transform: translateZ(1px);
  position: absolute;
  inset: 0;
  z-index: 20;
  mix-blend-mode: soft-light;
  background: url("../public/grain.svg"), radial-gradient( 
    farthest-corner circle at 
      var(--rare-x) var(--rare-y), 
      rgb(255, 255, 255) 0%, 
      rgba(133, 137, 141, .33) 45%, 
      rgba(100, 100, 100, .65) 120% 
    );
  background-blend-mode: screen, screen;
  filter: brightness(.9) contrast(1.75);
  opacity: calc(var(--rare-hyp) * .3);
  border-radius: 10px;
} */

/* WORKING */
/* .rare .card__layer2{
  transform: translateZ(1px);
  position: absolute;
  inset: 0;
  z-index: 30;
  mix-blend-mode: screen;
  background-size: cover, cover, 200% 700%, 300% 100%, 200% 100%;
  background-position: center, center, 0% var(--rare-posy), var(--rare-posx) var(--rare-posy), var(--rare-posx) var(--rare-posy);
  background-image: url("../public/grain.svg"), url("../public/2.png"),
  repeating-linear-gradient( 0deg, 
    rgb(255, 119, 115) calc(var(--step)*1), 
    rgba(255,237,95,1) calc(var(--step)*2), 
    rgba(168,255,95,1) calc(var(--step)*3), 
    rgba(131,255,247,1) calc(var(--step)*4), 
    rgba(120,148,255,1) calc(var(--step)*5), 
    rgb(216, 117, 255) calc(var(--step)*6), 
    rgb(255, 119, 115) calc(var(--step)*7)
  ),
  repeating-linear-gradient( 
    133deg, 
    #0e152e 0%, 
    hsl(180, 10%, 60%) 3.8%, 
    hsl(180, 29%, 66%) 4.5%, 
    hsl(180, 10%, 60%) 5.2%, 
    #0e152e 10% , 
    #0e152e 12% 
    ),
  radial-gradient(
    farthest-corner circle 
    at var(--rare-x) var(--rare-y),
    rgba(0, 0, 0, .1) 12%, 
    rgba(0, 0, 0, .15) 20%, 
    rgba(0, 0, 0, .25) 120%
  );
  background-blend-mode: screen, exclusion, hue, hard-light;
  filter: brightness(calc((var(--rare-hyp)*0.3) + 0.4)) contrast(2) saturate(1.5);
  -webkit-mask-image: url("https://i.ibb.co/FXTYjWj/2.png"); 
  -webkit-mask-size: cover; 
  border-radius: 10px;
}  */


/* LEGENDARY */
/* *************************************************************************** */

/* .legendary .card__layer1{
  position: absolute;
  inset: 0;
  background-image: radial-gradient(farthest-corner circle at var(--legendary-x) var(--legendary-y),rgb(255,255,255) 0%,rgba(133,137,141,.33) 45%,rgba(100,100,100,.65) 120%);
  filter: brightness(.9) contrast(1.75);
  opacity: calc(var(--legendary-o) * .66); 
  mix-blend-mode: overlay;
}

.legendary .card__layer2{
  position: absolute;
  inset: 0;
  background-image: url("https://assets.codepen.io/13471/sparkles.gif"),
    repeating-linear-gradient( 0deg,
      rgb(255, 119, 115) calc(var(--step)*1),
      rgba(255,237,95,1) calc(var(--step)*2),
      rgba(168,255,95,1) calc(var(--step)*3),
      rgba(131,255,247,1) calc(var(--step)*4),
      rgba(120,148,255,1) calc(var(--step)*5),
      rgb(216, 117, 255) calc(var(--step)*6),
      rgb(255, 119, 115) calc(var(--step)*7)
  ), repeating-linear-gradient(
    133deg,
    #0e152e 0%,
    hsl(180, 10%, 60%) 3.8%,
    hsl(180, 29%, 66%) 4.5%,
    hsl(180, 10%, 60%) 5.2%,
    #0e152e 10%,
    #0e152e 12%
  ),
  radial-gradient(
    farthest-corner circle at var(--legendary-x) var(--legendary-y),
    rgba(0, 0, 0, .1) 12%,
    rgba(0, 0, 0, .15) 20%,
    rgba(0, 0, 0, .25) 120%
  );
  background-size: 500px 100%, 200% 700%, 300% 100%, 200% 100%;
  background-position: center, 0% var(--legendary-posy), var(--legendary-posx) var(--legendary-posy), var(--legendary-posx) var(--legendary-posy);
  filter: brightness(.8) contrast(2.95) saturate(.5);
  background-blend-mode: screen, hue, hard-light;
  mix-blend-mode: color-dodge;
  display: grid;
  grid-area: 1/1;
  opacity: calc(var(--legendary-hyp) + 0.2);
  -webkit-mask-image: url("https://i.ibb.co/FXTYjWj/2.png");
  -webkit-mask-size: cover;
}

.legendary .card__layer2::after{
  content: '';
  position: absolute;
  z-index: 30;
  inset: 0;
  background-image: url("https://assets.codepen.io/13471/sparkles.gif"),
  repeating-linear-gradient( 0deg,
    rgb(255, 119, 115) calc(var(--step)*1),
    rgba(255,237,95,1) calc(var(--step)*2),
    rgba(168,255,95,1) calc(var(--step)*3),
    rgba(131,255,247,1) calc(var(--step)*4),
    rgba(120,148,255,1) calc(var(--step)*5),
    rgb(216, 117, 255) calc(var(--step)*6),
    rgb(255, 119, 115) calc(var(--step)*7)
  ), repeating-linear-gradient(
    133deg,
    #0e152e 0%,
    hsl(180, 10%, 60%) 3.8%,
    hsl(180, 29%, 66%) 4.5%,
    hsl(180, 10%, 60%) 5.2%,
    #0e152e 10%,
    #0e152e 12%
  ),
  radial-gradient(
    farthest-corner circle at var(--legendary-x) var(--legendary-y),
    rgba(0, 0, 0, .1) 12%,
    rgba(0, 0, 0, .15) 20%,
    rgba(0, 0, 0, .25) 120%
  );
  grid-area: 1/1;
  mix-blend-mode: soft-light;
  background-blend-mode: screen, hue, hard-light;
  background-position: center, 0% var(--legendary-posy), calc( var(--legendary-posx) * -1) calc( var(--legendary-posy) * -1), var(--legendary-posx) var(--legendary-posy);
  background-size: 500px 100%, 200% 400%, 195% 100%, 200% 100%;
  filter: brightness(1) contrast(2.5) saturate(1.75);
  opacity: calc(var(--legendary-o) * .66);
  -webkit-mask-image: url("https://i.ibb.co/FXTYjWj/2.png");
  -webkit-mask-size: cover;
} */
/* *************************************************************************** */

/* *************************************************************************** */



/* *************************************************************************** */
/* .limited-two .card__layer1{
  position: absolute;
  inset: 0;
  background-image: radial-gradient(farthest-corner circle at var(--limited-two-x) var(--limited-two-y), rgb(255,255,255) 0%,rgba(133,137,141,.33) 45%,rgba(100,100,100,.65) 120%);
  mix-blend-mode: overlay;
  opacity: calc(var(--limited-two) * .44);
  border-radius: 10px;
  filter: brightness(.9) contrast(1.75);
}

.limited-two .card__layer2{
  --h: 21;
  --s: 70%;
  --l: 50%;
  --bars: 4%;
  --bar-color: rgba(255, 255, 255, 0.6);
  --bar-bg: rgb(10, 10, 10);
  --vio: #c929f1;
  --blu: #0dbde9;
  --gre: #21e985;
  --yel: #eedf10;
  --red: #f80e7b; 
  position: absolute;
  inset: 0;
  background-image: repeating-linear-gradient( 90deg, 
      hsl(calc(var(--h)*0), var(--s), var(--l)) calc(var(--step)*0), hsl(calc(var(--h)*0), var(--s), var(--l)) calc(var(--step)*1), 
      black calc(var(--step)*1.001), black calc(var(--step)*1.999),
      hsl(calc(var(--h)*1), var(--s), var(--l)) calc(var(--step)*2), hsl(calc(var(--h)*1), var(--s), var(--l)) calc(var(--step)*3), 
      black calc(var(--step)*3.001), black calc(var(--step)*3.999),
      hsl(calc(var(--h)*2), var(--s), var(--l)) calc(var(--step)*4), hsl(calc(var(--h)*2), var(--s), var(--l)) calc(var(--step)*5), 
      black calc(var(--step)*5.001), black calc(var(--step)*5.999),
      hsl(calc(var(--h)*3), var(--s), var(--l)) calc(var(--step)*6), hsl(calc(var(--h)*3), var(--s), var(--l)) calc(var(--step)*7), 
      black calc(var(--step)*7.001), black calc(var(--step)*7.999),
      hsl(calc(var(--h)*4), var(--s), var(--l)) calc(var(--step)*8), hsl(calc(var(--h)*4), var(--s), var(--l)) calc(var(--step)*9), 
      black calc(var(--step)*9.001), black calc(var(--step)*9.999),
      hsl(calc(var(--h)*5), var(--s), var(--l)) calc(var(--step)*10), hsl(calc(var(--h)*5), var(--s), var(--l)) calc(var(--step)*11), 
      black calc(var(--step)*11.001), black calc(var(--step)*11.999),
      hsl(calc(var(--h)*6), var(--s), var(--l)) calc(var(--step)*12), hsl(calc(var(--h)*6), var(--s), var(--l)) calc(var(--step)*13), 
      black calc(var(--step)*13.001), black calc(var(--step)*13.999),
      hsl(calc(var(--h)*7), var(--s), var(--l)) calc(var(--step)*14), hsl(calc(var(--h)*7), var(--s), var(--l)) calc(var(--step)*15), 
      black calc(var(--step)*15.001), black calc(var(--step)*15.999),
      hsl(calc(var(--h)*8), var(--s), var(--l)) calc(var(--step)*16), hsl(calc(var(--h)*8), var(--s), var(--l)) calc(var(--step)*17), 
      black calc(var(--step)*17.001), black calc(var(--step)*17.999),
      hsl(calc(var(--h)*9), var(--s), var(--l)) calc(var(--step)*18), hsl(calc(var(--h)*9), var(--s), var(--l)) calc(var(--step)*19), 
      black calc(var(--step)*19.001), black calc(var(--step)*19.999),
      hsl(calc(var(--h)*10), var(--s), var(--l)) calc(var(--step)*20), hsl(calc(var(--h)*10), var(--s), var(--l)) calc(var(--step)*21), 
      black calc(var(--step)*21.001), black calc(var(--step)*21.999),
      hsl(calc(var(--h)*11), var(--s), var(--l)) calc(var(--step)*22), hsl(calc(var(--h)*11), var(--s), var(--l)) calc(var(--step)*23), 
      black calc(var(--step)*23.001), black calc(var(--step)*23.999),
      hsl(calc(var(--h)*12), var(--s), var(--l)) calc(var(--step)*24), hsl(calc(var(--h)*12), var(--s), var(--l)) calc(var(--step)*25), 
      black calc(var(--step)*25.001), black calc(var(--step)*25.999),
      hsl(calc(var(--h)*13), var(--s), var(--l)) calc(var(--step)*26), hsl(calc(var(--h)*13), var(--s), var(--l)) calc(var(--step)*27), 
      black calc(var(--step)*27.001), black calc(var(--step)*27.999),
      hsl(calc(var(--h)*14), var(--s), var(--l)) calc(var(--step)*28), hsl(calc(var(--h)*14), var(--s), var(--l)) calc(var(--step)*29), 
      black calc(var(--step)*29.001), black calc(var(--step)*29.999),
      hsl(calc(var(--h)*15), var(--s), var(--l)) calc(var(--step)*30), hsl(calc(var(--h)*15), var(--s), var(--l)) calc(var(--step)*31), 
      black calc(var(--step)*31.001), black calc(var(--step)*31.999)
    ),
    repeating-linear-gradient( 90deg, 
      var(--vio), var(--blu), var(--gre), var(--yel), var(--red), var(--vio)
    ),
    repeating-linear-gradient( 90deg, 
      var(--bar-bg) calc(var(--bars)*2), var(--bar-color) calc(var(--bars)*3), var(--bar-bg) calc(var(--bars)*3.5), var(--bar-color) calc(var(--bars)*4), var(--bar-bg) calc(var(--bars)*5), var(--bar-bg) calc(var(--bars)*12)
    ),
    repeating-linear-gradient( 90deg, 
      var(--bar-bg) calc(var(--bars)*2), var(--bar-color) calc(var(--bars)*3), var(--bar-bg) calc(var(--bars)*3.5), var(--bar-color) calc(var(--bars)*4), var(--bar-bg) calc(var(--bars)*5), var(--bar-bg) calc(var(--bars)*9)
    ),
    radial-gradient(
      farthest-corner circle 
        at var(--limited-two-x) var(--limited-two-y), 
        rgba(230, 230, 230, 0.85) 0%, 
        rgba(200, 200, 200, .1) 25%, 
        rgb(0, 0, 0) 90%
      );
  mix-blend-mode: color-dodge;
  background-blend-mode: soft-light, soft-light, screen, overlay;
  background-position: center, calc(((50% - var(--limited-two-posx)) * 25) + 50%) center, calc(var(--limited-two-posx)*-1.2) var(--limited-two-posy), var(--limited-two-posx) var(--limited-two-posy), center;
  -webkit-mask-image: url("https://i.ibb.co/FXTYjWj/2.png");
  -webkit-mask-size: cover;
} */
/* *************************************************************************** */

/* *************************************************************************** */
/* .gold-two .card__layer1{
  border-radius: 10px;
  position: absolute;
  inset: 0;
  background-image: radial-gradient(farthest-corner circle at var(--gold-two-x) var(--gold-two-y),rgb(255,255,255) 0%,rgba(133,137,141,.33) 45%,rgba(100,100,100,.65) 120%);
  filter: brightness(.9) contrast(1.75);
  opacity: calc(var(--gold-two-hyp) * .44);
  mix-blend-mode: overlay;
}

.gold-two .card__layer2{
  border-radius: 10px;
  position: absolute;
  inset: 0;
  background-image: url("../public/2.png"), url("https://assets.codepen.io/13471/sparkles.gif"),
    repeating-linear-gradient( var(--angle), 
      rgba(89, 46, 80, 0.5) 0%, 
      hsl(39, 37%, 60%) 2.5%, 
      rgb(216, 183, 92) 5%, 
      hsl(39, 37%, 60%) 7.5%, 
      rgba(14, 21, 46, 0.5) 10% , 
      rgba(14, 21, 46, 0.5) 15% 
    );
  background-size: cover, cover, 600% 100%;
  background-position: center, center, var(--gold-two-posx) var(--gold-two-posy);
  background-blend-mode: lighten, color-burn, darken;
  filter: brightness(calc((var(--gold-two-hyp)*0.4) + 0.7)) contrast(3) saturate(.66);
  mix-blend-mode: color-dodge;
} */

/* *************************************************************************** */

/* .legendary-two .card__layer1{
  position: absolute;
  inset: 0;
  z-index: 40;
  border-radius: 10px;
  mix-blend-mode: soft-light;
  background: url("../public/grain.svg"),
  radial-gradient(
    farthest-corner circle at var(--legendary-two-x) var(--legendary-two-y),
    rgba(255,255,255,.8) 10%,
    rgba(255,255,255,.6) 35%,
    rgba(0,0,0,1) 85%);
  background-blend-mode: screen, screen;
  filter: brightness(.8) contrast(1.3);
  mix-blend-mode: overlay;
  opacity: calc(var(--legendary-two-o) * .8);
  border-radius: 10px;
}

 .legendary-two .card__layer2{
  position: absolute;
  inset: 0;
  border-radius: 10px;
  background: transparent;
  background-image: 
    repeating-linear-gradient(-22deg,  
      rgba(174, 102, 202, 0.75) calc(var(--step)*1),
      rgba(228, 77, 72, 0.75) calc(var(--step)*2), 
      rgba(216, 197, 55, 0.75) calc(var(--step)*3),  
      rgba(124, 201, 62, 0.75) calc(var(--step)*4),  
      rgba(80, 177, 170, 0.75) calc(var(--step)*5),  
      rgba(136, 160, 255, 0.75) calc(var(--step)*6), 
      rgba(176, 105, 204, 0.75) calc(var(--step)*7)
    );
  clip-path: inset(2% 3% round 2% / 1.5%);
  background-blend-mode: color-dodge;
  mix-blend-mode: color-dodge;
  filter: brightness(calc((var(--legendary-two-hyp)*0.3) + 0.6)) contrast(2.3) saturate(1.1);
  background-size: 200% 400%;
  background-position: 0% var(--legendary-two-posy), var(--legendary-two-posx) var(--legendary-two-posy);
  opacity: var(--legendary-two-o);
  display: grid;
  -webkit-mask-image: url("https://i.ibb.co/FXTYjWj/2.png");
  -webkit-mask-size: cover;
}

.legendary-two .card__layer2::after{
  content: "";
  border-radius: 10px;
  transform: translateZ(1.2px);
  grid-area: 1/1;
  background-image: radial-gradient( 
    farthest-corner ellipse 
    at calc( ((var(--legendary-two-x)) * 0.5) + 25% ) calc( ((var(--legendary-two-y)) * 0.5) + 25% ), 
    rgb(255, 255, 255) 5%, 
    rgba(55, 0, 55, .6) 25%, 
    rgba(55, 55, 55) 90% 
  ), url("../public/sparkles.gif");
  background-size: 200% 200%, 200% 200%;
  filter: brightness(calc((var(--legendary-two-hyp)*0.2) + 0.4)) contrast(.85) saturate(1.1);
  background-position: center, center;
  mix-blend-mode: hard-light;
  -webkit-mask-image: url("https://i.ibb.co/FXTYjWj/2.png");
  -webkit-mask-size: cover;
} */

/* *************************************************************************** */

/* .limited-three .card__layer1 {
  position: absolute;
  inset: 0;
  background-image: radial-gradient(farthest-corner circle at var(--limited-three-x) var(--limited-three-y),rgb(255,255,255) 0%,rgba(133,137,141,.33) 45%,rgba(100,100,100,.65) 120%);
  filter: brightness(.9) contrast(1.75);
  opacity: calc(var(--limited-three-hyp)*.44);
  display: grid;
  grid-area: 1/1;
  mix-blend-mode: overlay;
  border-radius: 10px;
}

.limited-three .card__layer2{
  position: absolute;
  inset: 0;
  background-image: url("../public/illusion.png"),
  repeating-linear-gradient( 0deg, 
    rgb(255, 119, 115) calc(var(--step)*1), 
    rgba(255,237,95,1) calc(var(--step)*2), 
    rgba(168,255,95,1) calc(var(--step)*3), 
    rgba(131,255,247,1) calc(var(--step)*4), 
    rgba(120,148,255,1) calc(var(--step)*5), 
    rgb(216, 117, 255) calc(var(--step)*6), 
    rgb(255, 119, 115) calc(var(--step)*7)
  ),
  repeating-linear-gradient( 
    var(--angle), 
    #0e152e 0%, 
    hsl(180, 10%, 60%) 3.8%, 
    hsl(180, 29%, 66%) 4.5%, 
    hsl(180, 10%, 60%) 5.2%, 
    #0e152e 10% , 
    #0e152e 12% 
    ),
  radial-gradient(
    farthest-corner circle 
    at var(--limited-three-x) var(--limited-three-y),
    rgba(0, 0, 0, .1) 12%, 
    rgba(0, 0, 0, .15) 20%, 
    rgba(0, 0, 0, .25) 120%
  );
  background-size: 50% 42%, 200% 700%, 300% 250%, 200% 100%;
  background-blend-mode: exclusion, hue, hard-light;
  mix-blend-mode: color-dodge;
  filter: brightness(calc((var(--limited-three-hyp)*0.3) + 0.5)) contrast(2) saturate(1.5);
  background-position: center center, 0% var(--limited-three-posy), var(--limited-three-posx) var(--limited-three-posy), var(--limited-three-posx) var(--limited-three-posy);
  border-radius: 10px;
}

.limited-three .card__layer2::after{
  content: "";
  position: absolute;
  inset: 0;
  background-image: url("../public/illusion.png"),
  repeating-linear-gradient( 0deg, 
    rgb(255, 119, 115) calc(var(--step)*1), 
    rgba(255,237,95,1) calc(var(--step)*2), 
    rgba(168,255,95,1) calc(var(--step)*3), 
    rgba(131,255,247,1) calc(var(--step)*4), 
    rgba(120,148,255,1) calc(var(--step)*5), 
    rgb(216, 117, 255) calc(var(--step)*6), 
    rgb(255, 119, 115) calc(var(--step)*7)
  ),
  repeating-linear-gradient( 
    var(--angle), 
    #0e152e 0%, 
    hsl(180, 10%, 60%) 3.8%, 
    hsl(180, 29%, 66%) 4.5%, 
    hsl(180, 10%, 60%) 5.2%, 
    #0e152e 10% , 
    #0e152e 12% 
    ),
  radial-gradient(
    farthest-corner circle 
    at var(--limited-three-x) var(--limited-three-y),
    rgba(0, 0, 0, .1) 12%, 
    rgba(0, 0, 0, .15) 20%, 
    rgba(0, 0, 0, .25) 120%
  );
  background-size: 50% 42%, 200% 400%, 195% 250%, 200% 100%;
  background-position: center center, 0% var(--limited-three-posy), calc( var(--limited-three-posx) * -2) calc( var(--limited-three-posy) * -1), var(--limited-three-posx) var(--limited-three-posy);
  filter: brightness(calc((var(--limited-three-hyp)*0.5) + .8)) contrast(1.6) saturate(1.4);
  mix-blend-mode: exclusion;
  background-blend-mode: exclusion, hue, hard-light;
  border-radius: 10px;
}

.rare-three .card__layer1 {
  z-index: 30;
  position: absolute;
  inset: 0;
  background-image: radial-gradient(farthest-corner circle at var(--rare-three-x) var(--rare-three-y),rgb(255,255,255) 0%,rgba(133,137,141,.33) 45%,rgba(100,100,100,.65) 120%);
  filter: brightness(.9) contrast(1.75);
  opacity: calc(var(--rare-three-hyp)*.44);
  display: grid;
  grid-area: 1/1;
  mix-blend-mode: overlay;
  border-radius: 10px;
}

.rare-three .card__layer2{
  position: absolute;
  inset: 0;
  background-image: url("../public/illusion.png"),
  repeating-linear-gradient( 0deg, 
    rgb(255, 119, 115) calc(var(--step)*1), 
    rgba(255,237,95,1) calc(var(--step)*2), 
    rgba(168,255,95,1) calc(var(--step)*3), 
    rgba(131,255,247,1) calc(var(--step)*4), 
    rgba(120,148,255,1) calc(var(--step)*5), 
    rgb(216, 117, 255) calc(var(--step)*6), 
    rgb(255, 119, 115) calc(var(--step)*7)
  ),
  repeating-linear-gradient( 
    var(--angle), 
    #0e152e 0%, 
    hsl(180, 10%, 60%) 3.8%, 
    hsl(180, 29%, 66%) 4.5%, 
    hsl(180, 10%, 60%) 5.2%, 
    #0e152e 10% , 
    #0e152e 12% 
    ),
  radial-gradient(
    farthest-corner circle 
    at var(--rare-three-x) var(--rare-three-y),
    rgba(0, 0, 0, .1) 12%, 
    rgba(0, 0, 0, .15) 20%, 
    rgba(0, 0, 0, .25) 120%
  );
  background-size: 50% 42%, 200% 700%, 300% 100%, 200% 100%;
  background-blend-mode: exclusion, hue, hard-light;
  mix-blend-mode: color-dodge;
  filter: brightness(calc((var(--rare-three-hyp)*0.3) + 0.5)) contrast(2) saturate(1.5);
  background-position: center center, 0% var(--rare-three-posy), var(--rare-three-posx) var(--rare-three-posy), var(--rare-three-posx) var(--rare-three-posy);
}

.rare-three .card__layer2::after{
  content: "";
  position: absolute;
  inset: 0;
  background-image: url("../public/illusion.png"),
  repeating-linear-gradient( 0deg, 
    rgb(255, 119, 115) calc(var(--step)*1), 
    rgba(255,237,95,1) calc(var(--step)*2), 
    rgba(168,255,95,1) calc(var(--step)*3), 
    rgba(131,255,247,1) calc(var(--step)*4), 
    rgba(120,148,255,1) calc(var(--step)*5), 
    rgb(216, 117, 255) calc(var(--step)*6), 
    rgb(255, 119, 115) calc(var(--step)*7)
  ),
  repeating-linear-gradient( 
    var(--angle), 
    #0e152e 0%, 
    hsl(180, 10%, 60%) 3.8%, 
    hsl(180, 29%, 66%) 4.5%, 
    hsl(180, 10%, 60%) 5.2%, 
    #0e152e 10% , 
    #0e152e 12% 
    ),
  radial-gradient(
    farthest-corner circle 
    at var(--rare-three-x) var(--rare-three-y),
    rgba(0, 0, 0, .1) 12%, 
    rgba(0, 0, 0, .15) 20%, 
    rgba(0, 0, 0, .25) 120%
  );
  background-size: 50% 42%, 200% 400%, 250% 100%, 200% 100%;
  background-position: center center, 0% var(--rare-three-posy), calc( var(--rare-three-posx) * -2) calc( var(--rare-three-posy) * -1), var(--rare-three-posx) var(--rare-three-posy);
  filter: brightness(calc((var(--rare-three-hyp)*0.5) + .8)) contrast(1.6) saturate(1.4);
  mix-blend-mode: exclusion;
  background-blend-mode: exclusion, hue, hard-light;
} */

/* .gold-three .card__layer1{
  position: absolute;
  inset: 0;
  background: radial-gradient(farthest-corner circle at var(--gold-three-x) var(--gold-three-y),rgba(255,255,255,.75) 0%,rgba(99,99,99,.35) 45%,rgba(0,0,0,1) 120%);
  filter: brightness(.9) contrast(1.75);
  opacity: var(--gold-three-o) * .66;
  mix-blend-mode: overlay;
  z-index: 30;
  border-radius: 10px;
}

.gold-three .card__layer2{
  position: absolute;
  inset: 0;
  z-index: 30;
  background-image: url("../public/vmaxbg.jpeg"),
    repeating-linear-gradient( -33deg, 
      rgb(206, 42, 36) calc(6%*1),  
      rgb(157, 170, 223) calc(6%*2), 
      rgb(45, 153, 146) calc(6%*3), 
      rgb(29, 151, 36) calc(6%*4), 
      rgb(181, 64, 228) calc(6%*5), 
      rgb(206, 42, 36) calc(6%*6)
    ),
    repeating-linear-gradient( 
      133deg, 
      rgba(14, 21, 46, 0.5) 0%, 
      hsl(180, 10%, 50%) 2.5%, 
      hsl(83, 50%, 35%) 5%, 
      hsl(180, 10%, 50%) 7.5%, 
      rgba(14, 21, 46, 0.5) 10% , 
      rgba(14, 21, 46, 0.5) 15% 
      ),
    radial-gradient(
      farthest-corner circle 
      at var(--gold-three-x) var(--gold-three-y),
      rgba(6, 218, 255, 0.6) 0%, 
      rgba(38, 235, 127, 0.6) 25%, 
      rgba(155, 78, 228, 0.6) 50%, 
      rgba(228, 78, 90, 0.6) 75%
    );
    background-blend-mode: color-burn, screen, soft-light;
    background-size: 50% 42%, 1100% 1100%, 600% 600%, 200% 200%;
    background-position: center, 
    0% var(--gold-three-posy), 
    var(--gold-three-posx) var(--gold-three-posy), 
    var(--gold-three-posx) var(--gold-three-posy);
    filter: brightness(calc((var(--gold-three-hyp)*0.3) + 0.5)) contrast(2.5) saturate(.6);
    mix-blend-mode: color-dodge;
    opacity: var(--gold-three-hyp);
} */
