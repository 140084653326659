.Limited .card__layer1 {
  will-change: background-image;
  border-radius: 4%;
  position: absolute;
  inset: 0;
  filter: brightness(.8) contrast(0.6);
  background-image: radial-gradient(farthest-corner circle at var(--Limited-x) var(--Limited-y),
      rgba(222, 245, 250, 0.7) 10%,
      rgba(255, 255, 255, 0.5) 20%,
      rgba(0, 0, 0, 0.5) 90%);
  mix-blend-mode: overlay;
}

.Limited .card__layer2 {
  will-change: opacity, background-image;
  position: absolute;
  inset: 0;
  background: var(--sparkles),
    var(--sparkles),
    var(--sparkles),
    repeating-linear-gradient(82deg,
      rgb(219, 204, 86) calc(var(--step)*1),
      rgb(121, 199, 58) calc(var(--step)*2),
      rgb(58, 192, 183) calc(var(--step)*3),
      rgb(71, 98, 207) calc(var(--step)*4),
      rgb(170, 69, 209) calc(var(--step)*5),
      rgb(255, 90, 180) calc(var(--step)*6),
      rgb(255, 90, 180) calc(var(--step)*7),
      rgb(170, 69, 209) calc(var(--step)*8),
      rgb(71, 98, 207) calc(var(--step)*9),
      rgb(58, 192, 183) calc(var(--step)*10),
      rgb(121, 199, 58) calc(var(--step)*11),
      rgb(219, 204, 86) calc(var(--step)*12)),
    radial-gradient(farthest-corner circle at var(--Limited-x) var(--Limited-y),
      rgba(255, 255, 255, 0.6) 5%,
      rgba(150, 150, 150, .3) 40%,
      rgb(0, 0, 0) 100%);
  background-blend-mode: color-dodge, color-burn, saturation, screen;
  mix-blend-mode: color-dodge;
  background-size: cover, cover, cover, 400% 900%, cover;
  opacity: calc((var(--Limited-bright) * .4) + 0.1);
  border-radius: 4%;
  -webkit-mask-image: var(--Limited-maskUrl);
  -webkit-mask-size: cover;
}

.Limited .card__layer3 {
  position: absolute;
  inset: 0;
  z-index: 30;
  background-image: url("./grain.svg");
  mix-blend-mode: lighten;
  opacity: .5;
  border-radius: 4%;
}

.Rare .card__layer1 {
  will-change: background-image, opacity;
  position: absolute;
  inset: 0;
  background-image: radial-gradient(farthest-corner circle at var(--Rare-x) var(--Rare-y), rgb(255, 255, 255) 0%, rgba(133, 137, 141, .33) 45%, rgba(100, 100, 100, .65) 120%);
  filter: brightness(.9) contrast(1.75);
  display: grid;
  grid-area: 1/1;
  mix-blend-mode: overlay;
  border-radius: 4%;
  opacity: calc(var(--Rare-bright) * 0.488);
}

.Rare .card__layer2 {
  will-change: background-image, filter, background-position;
  position: absolute;
  z-index: 20;
  inset: 0;
  background-color: transparent;
  background-image: var(--sparkles),
    repeating-linear-gradient(0deg,
      rgb(255, 119, 115) calc(3%*1),
      rgba(255, 237, 95, 1) calc(3%*2),
      rgba(168, 255, 95, 1) calc(3%*3),
      rgba(131, 255, 247, 1) calc(3%*4),
      rgba(120, 148, 255, 1) calc(3%*5),
      rgb(216, 117, 255) calc(3%*6),
      rgb(255, 119, 115) calc(3%*7)),
    repeating-linear-gradient(133deg,
      rgba(65, 65, 65, 1) 0%,
      hsl(180, 10%, 60%) 3.8%,
      hsl(180, 29%, 66%) 4.5%,
      hsl(180, 10%, 60%) 5.2%,
      rgba(65, 65, 65, 1) 10%,
      rgba(65, 65, 65, 1) 12%),
    radial-gradient(farthest-corner circle at var(--Rare-x) var(--Rare-y),
      rgba(0, 0, 0, .1) 12%,
      rgba(0, 0, 0, .15) 20%,
      rgba(0, 0, 0, .25) 120%);
  background-size: cover, 200% 700%, 250% 100%, 200% 100%;
  background-blend-mode: exclusion, hue, hard-light;
  mix-blend-mode: color-dodge;
  filter: brightness(1) contrast(2) saturate(1.5);
  background-position: center center, 0% var(--Rare-posy), var(--Rare-posx) var(--Rare-posy), var(--Rare-posx) var(--Rare-posy);
  -webkit-mask-image: var(--Rare-maskUrl);
  -webkit-mask-size: cover;
  opacity: calc(var(--Rare-bright) * 0.2 + 0.5);
  border-radius: 4%;
}

.Rare .card__layer2::after {
  will-change: background-image, filter, background-position;
  content: "";
  position: absolute;
  inset: 0;
  background-image: var(--sparkles),
    repeating-linear-gradient(0deg,
      rgb(255, 119, 115) calc(3%*1),
      rgba(255, 237, 95, 1) calc(3%*2),
      rgba(168, 255, 95, 1) calc(3%*3),
      rgba(131, 255, 247, 1) calc(3%*4),
      rgba(120, 148, 255, 1) calc(3%*5),
      rgb(216, 117, 255) calc(3%*6),
      rgb(255, 119, 115) calc(3%*7)),
    repeating-linear-gradient(133deg,
      rgba(65, 65, 65, 1) 0%,
      hsl(180, 10%, 60%) 3.8%,
      hsl(180, 29%, 66%) 4.5%,
      hsl(180, 10%, 60%) 5.2%,
      rgba(65, 65, 65, 1) 10%,
      rgba(65, 65, 65, 1) 12%),
    radial-gradient(farthest-corner circle at var(--Rare-x) var(--Rare-y),
      rgba(0, 0, 0, .1) 12%,
      rgba(0, 0, 0, .15) 20%,
      rgba(0, 0, 0, .25) 120%);
  background-size: cover, 200% 700%, 250% 100%, 200% 100%;
  background-position: center, 0% var(--Rare-posy), calc(var(--Rare-posx) * -2) calc(var(--Rare-posy) * -1), var(--Rare-posx) var(--Rare-posy);
  filter: brightness(0.8) contrast(1.6) saturate(1.4);
  mix-blend-mode: exclusion;
  background-blend-mode: exclusion, hue, hard-light;
  -webkit-mask-image: var(--Rare-maskUrl);
  -webkit-mask-size: cover;
  opacity: calc(var(--Rare-bright) * 0.2 + 0.5);
  border-radius: 4%;
}

.Rare .card__layer3 {
  will-change: opacity;
  position: absolute;
  inset: 0;
  border-radius: 4%;
  z-index: 30;
  background-image: var(--sparkles);
  background-size: cover;
  background-position: center center;
  mix-blend-mode: lighten;
  background-blend-mode: exclusion;
  -webkit-mask-image: var(--Rare-maskUrl);
  -webkit-mask-size: cover;
  opacity: calc(var(--Rare-bright) * 0.2 + 0.5);
}

.Epic .card__layer1 {
  will-change: background-image, opacity;
  position: absolute;
  inset: 0;
  background-image: radial-gradient(farthest-corner circle at var(--Epic-x) var(--Epic-y), rgb(255, 255, 255) 0%, rgba(133, 137, 141, .33) 45%, rgba(100, 100, 100, .65) 120%);
  filter: brightness(.9) contrast(1.75);
  display: grid;
  grid-area: 1/1;
  mix-blend-mode: overlay;
  border-radius: 4%;
  opacity: calc(var(--Epic-bright) * 0.488);
}

.Epic .card__layer2 {
  will-change: background-image, filter, background-position;
  position: absolute;
  z-index: 20;
  inset: 0;
  background-color: transparent;
  background-image: var(--legendaryPattern),
    repeating-linear-gradient(0deg,
      rgb(255, 119, 115) calc(3%*1),
      rgba(255, 237, 95, 1) calc(3%*2),
      rgba(168, 255, 95, 1) calc(3%*3),
      rgba(131, 255, 247, 1) calc(3%*4),
      rgba(120, 148, 255, 1) calc(3%*5),
      rgb(216, 117, 255) calc(3%*6),
      rgb(255, 119, 115) calc(3%*7)),
    repeating-linear-gradient(133deg,
      rgba(65, 65, 65, 1) 0%,
      hsl(180, 10%, 60%) 3.8%,
      hsl(180, 29%, 66%) 4.5%,
      hsl(180, 10%, 60%) 5.2%,
      rgba(65, 65, 65, 1) 10%,
      rgba(65, 65, 65, 1) 12%),
    radial-gradient(farthest-corner circle at var(--Epic-x) var(--Epic-y),
      rgba(0, 0, 0, .1) 12%,
      rgba(0, 0, 0, .15) 20%,
      rgba(0, 0, 0, .25) 120%);
  background-size: cover, 200% 700%, 250% 100%, 200% 100%;
  background-blend-mode: darken, hue, hard-light;
  mix-blend-mode: color-dodge;
  filter: brightness(1) contrast(2) saturate(1.5);
  background-position: center center, 0% var(--Epic-posy), var(--Epic-posx) var(--Epic-posy), var(--Epic-posx) var(--Epic-posy);
  -webkit-mask-image: var(--Epic-maskUrl);
  -webkit-mask-size: cover;
  opacity: 1;
  border-radius: 4%;
}

.Epic .card__layer2::after {
  will-change: background-image, filter, background-position;
  content: "";
  position: absolute;
  inset: 0;
  background-image: var(--legendaryPattern),
    repeating-linear-gradient(0deg,
      rgb(255, 119, 115) calc(3%*1),
      rgba(255, 237, 95, 1) calc(3%*2),
      rgba(168, 255, 95, 1) calc(3%*3),
      rgba(131, 255, 247, 1) calc(3%*4),
      rgba(120, 148, 255, 1) calc(3%*5),
      rgb(216, 117, 255) calc(3%*6),
      rgb(255, 119, 115) calc(3%*7)),
    repeating-linear-gradient(133deg,
      rgba(65, 65, 65, 1) 0%,
      hsl(180, 10%, 60%) 3.8%,
      hsl(180, 29%, 66%) 4.5%,
      hsl(180, 10%, 60%) 5.2%,
      rgba(65, 65, 65, 1) 10%,
      rgba(65, 65, 65, 1) 12%),
    radial-gradient(farthest-corner circle at var(--Epic-x) var(--Epic-y),
      rgba(0, 0, 0, .1) 12%,
      rgba(0, 0, 0, .15) 20%,
      rgba(0, 0, 0, .25) 120%);
  background-size: cover, 200% 700%, 250% 100%, 200% 100%;
  background-position: center, 0% var(--Epic-posy), calc(var(--Epic-posx) * -2) calc(var(--Epic-posy) * -1), var(--Epic-posx) var(--Epic-posy);
  filter: brightness(0.8) contrast(1.6) saturate(1.4);
  mix-blend-mode: exclusion;
  background-blend-mode: darken, hue, hard-light;
  -webkit-mask-image: var(--Epic-maskUrl);
  -webkit-mask-size: cover;
  opacity: 1;
  border-radius: 4%;
}

.Epic .card__layer3 {
  will-change: opacity;
  position: absolute;
  inset: 0;
  border-radius: 4%;
  z-index: 30;
  background-image: var(--sparklesEpic);
  background-size: cover;
  background-position: center center;
  mix-blend-mode: lighten;
  background-blend-mode: exclusion;
  -webkit-mask-image: var(--Epic-maskUrl);
  -webkit-mask-size: cover;
  opacity: calc(var(--Epic-bright) + 0.7);
}

/* .Epic .card__layer0{
  position: absolute;
  inset: 0;
  z-index: 40;
  background-image: var(--glitter),
    repeating-linear-gradient(110deg,
      rgba(89, 46, 80, 0.5) 0%,
      hsl(39, 37%, 60%) 2.5%,
      rgb(216, 183, 92) 4%,
      hsl(39, 37%, 60%) 6.5%,
      rgba(14, 21, 46, 0.5) 10%,
      rgba(14, 21, 46, 0.5) 15%),
      var(--metal);
  background-size: 25% 25%, 600% 100%, 28% 23%;
  background-position: center, var(--Epic-posx) var(--Epic-posy), center;
  background-blend-mode: color-burn, darken;
  filter: brightness(calc((var(--Epic-bright)*0.4) + 0.7)) contrast(3) saturate(.66);
  mix-blend-mode: color-dodge;
  -webkit-mask-image: var(--Legendary-maskUrl);
  -webkit-mask-size: cover;
}
.Epic .card__layer0::after{
  content: "";
  position: absolute;
  inset: 0;
  z-index: 41;
  background-image: var(--glitter),
  repeating-linear-gradient(110deg,
    rgba(89, 46, 80, 0.5) 0%,
    hsl(39, 37%, 60%) 2.5%,
    rgb(216, 183, 92) 4%,
    hsl(39, 37%, 60%) 6.5%,
    rgba(14, 21, 46, 0.5) 10%,
    rgba(14, 21, 46, 0.5) 15%);
  background-position: center, calc(var(--Epic-posx) * -1) calc(var(--Epic-posy) * -1), center;
  filter: brightness(calc((var(--Epic-bright)*0.3) + 0.7)) contrast(2.5) saturate(.66);
  mix-blend-mode: exclusion;
  background-size: 10% 10%, 600% 100%;
  background-blend-mode: color-burn, darken;
  -webkit-mask-image: var(--Legendary-maskUrl);
  -webkit-mask-size: cover;
} */


/* .Legendary .card__layer1 {
  will-change: background-image, opacity;
  position: absolute;
  inset: 0;
  background-image: radial-gradient(farthest-corner circle at var(--Legendary-x) var(--Legendary-y),rgb(255,255,255) 0%,rgba(133,137,141,.33) 45%,rgba(100,100,100,.65) 120%);
  filter: brightness(.9) contrast(1.75);
  display: grid;
  grid-area: 1/1;
  mix-blend-mode: overlay;
  border-radius: 4%;
  opacity: calc(var(--Legendary-bright) * 0.488);
} */
.Legendary .card__layer2 {
  will-change: background-image, filter, background-position;
  position: absolute;
  z-index: 20;
  inset: 0;
  background-color: transparent;
  background-image: var(--legendaryPattern),
    repeating-linear-gradient(0deg,
      rgb(255, 119, 115) calc(3%*1),
      rgba(255, 237, 95, 1) calc(3%*2),
      rgba(168, 255, 95, 1) calc(3%*3),
      rgba(131, 255, 247, 1) calc(3%*4),
      rgba(120, 148, 255, 1) calc(3%*5),
      rgb(216, 117, 255) calc(3%*6),
      rgb(255, 119, 115) calc(3%*7)),
    repeating-linear-gradient(133deg,
      rgba(65, 65, 65, 1) 0%,
      hsl(180, 10%, 60%) 3.8%,
      hsl(180, 29%, 66%) 4.5%,
      hsl(180, 10%, 60%) 5.2%,
      rgba(65, 65, 65, 1) 10%,
      rgba(65, 65, 65, 1) 12%),
    radial-gradient(farthest-corner circle at var(--Legendary-x) var(--Legendary-y),
      rgba(0, 0, 0, .1) 12%,
      rgba(0, 0, 0, .15) 20%,
      rgba(0, 0, 0, .25) 120%);
  background-size: cover, 200% 700%, 250% 100%, 200% 100%;
  background-blend-mode: darken, hue, hard-light;
  mix-blend-mode: color-dodge;
  filter: brightness(1) contrast(2) saturate(1.5);
  background-position: center center, 0% var(--Legendary-posy), var(--Legendary-posx) var(--Legendary-posy), var(--Legendary-posx) var(--Legendary-posy);
  -webkit-mask-image: var(--Legendary-maskUrl);
  -webkit-mask-size: cover;
  opacity: 1;
  border-radius: 4%;
}

.Legendary .card__layer2::after {
  will-change: filter, background-position;
  content: "";
  position: absolute;
  inset: 0;
  background-image: var(--legendaryPattern),
    repeating-linear-gradient(0deg,
      rgb(255, 119, 115) calc(3%*1),
      rgba(255, 237, 95, 1) calc(3%*2),
      rgba(168, 255, 95, 1) calc(3%*3),
      rgba(131, 255, 247, 1) calc(3%*4),
      rgba(120, 148, 255, 1) calc(3%*5),
      rgb(216, 117, 255) calc(3%*6),
      rgb(255, 119, 115) calc(3%*7)),
    repeating-linear-gradient(133deg,
      rgba(65, 65, 65, 1) 0%,
      hsl(180, 10%, 60%) 3.8%,
      hsl(180, 29%, 66%) 4.5%,
      hsl(180, 10%, 60%) 5.2%,
      rgba(65, 65, 65, 1) 10%,
      rgba(65, 65, 65, 1) 12%),
    radial-gradient(farthest-corner circle at var(--Legendary-x) var(--Legendary-y),
      rgba(0, 0, 0, .1) 12%,
      rgba(0, 0, 0, .15) 20%,
      rgba(0, 0, 0, .25) 120%);
  background-size: cover, 200% 700%, 250% 100%, 200% 100%;
  background-position: center, 0% var(--Legendary-posy), calc(var(--Legendary-posx) * -2) calc(var(--Legendary-posy) * -1), var(--Legendary-posx) var(--Legendary-posy);
  filter: brightness(0.8) contrast(1.6) saturate(1.4);
  mix-blend-mode: exclusion;
  background-blend-mode: darken, hue, hard-light;
  -webkit-mask-image: var(--Legendary-maskUrl);
  -webkit-mask-size: cover;
  opacity: 1;
  border-radius: 4%;
}

.Legendary .card__layer3 {
  will-change: opacity;
  position: absolute;
  inset: 0;
  border-radius: 4%;
  z-index: 30;
  background-image: var(--sparkles);
  background-size: cover;
  background-position: center center;
  mix-blend-mode: lighten;
  background-blend-mode: exclusion;
  -webkit-mask-image: var(--Legendary-maskUrl);
  -webkit-mask-size: cover;
  opacity: calc(var(--Legendary-bright) + 0.7);
}

.Legendary #card-image {
  z-index: 20;
  position: relative;
}

.Legendary .card-animated-bg {
  pointer-events: none;
  position: absolute;
  z-index: 10;
  inset: -6px;
  will-change: inset;
  animation: animatedinset 1.5s ease-in-out infinite;
}

.Legendary .card-animated-bg::after {
  content: "";
  position: absolute;
  will-change: background-position;
  inset: 0;
  background: linear-gradient(60deg, #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
  background-size: 300% 300%;
  animation: animatedgradient 3s ease infinite;
  transition: all 1s linear;
  border-radius: 4%;
  filter: blur(6px);
}

/* .Epic #card-image{
  z-index: 20;
  position: relative;
}
.Epic .card-animated-bg{
  pointer-events: none;
  position: absolute;
  z-index: 10;
  inset: -1px;  
}
.Epic .card-animated-bg::after{
  content: "";
  position: absolute;
  inset: 0;
  background: linear-gradient(60deg, #FEDB37, #FDB931, #9f7928, #8A6E2F, #D1B464, #C9A401, #5d4a1f, #5d4a1f);
  background-size: 300% 300%;
  animation: animatedgradient 3s ease alternate infinite;
  border-radius: 4%;
  filter: blur(6px);
} */
@keyframes animatedgradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes animatedinset {
  0% {
    inset: 0px;
  }

  50% {
    inset: -7px;
  }

  100% {
    inset: 0px;
  }
}